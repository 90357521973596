let towns;

function mainSliderChangeCity(id_active_city) {
    let slider_titles = document.querySelectorAll('.main-header__slider-item-title');

    for (let item of slider_titles) {
        if (item.querySelector('.main-header__slider-item-title_city')) {
            item.removeChild(item.querySelector('.main-header__slider-item-title_city'));
        }
        item.innerHTML +=
            ' <p class="main-header__slider-item-title_city"> в городе ' +
                `<span class="blue">${towns[id_active_city].name}</span>` +
            '</p>';
    }
}
//установка cookie
function setCookie(name, value, options) {
    options = options || {};

    var expires = options.expires;

    if (typeof expires == "number" && expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    var updatedCookie = name + "=" + value;

    for (var propName in options) {
        updatedCookie += "; " + propName;
        var propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    document.cookie = updatedCookie;
}

// возвращает cookie с именем name, если есть, если нет, то undefined
function getCookie(name) {
    var matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function deleteCookie(name) {
    setCookie(name, "", {
        expires: -1
    })
}

//deleteCookie('town_id');
//setCookie("town_id","",{expires:-1});

//добавить во все формы поле почты
(() => {
    let all_form = document.querySelectorAll('form');
    let elem_form = document.createElement("input"),
        elem_form2 = document.createElement("input");

    elem_form.type = "hidden";
    elem_form.setAttribute('name', 'mail_city');
    elem_form.setAttribute('value', '');
    elem_form.className = 'hidden-input_mail';

    elem_form2.type = "hidden";
    elem_form2.setAttribute('name', 'city');
    elem_form2.setAttribute('value', '');
    elem_form2.className = 'hidden-input_city';

    for(let form of all_form){
        form.appendChild(elem_form.cloneNode(true));
        form.appendChild(elem_form2.cloneNode(true));
    }
})();

//отправляем запрос на получение json с данными городов и потом много запутанной магии
fetch(
    "https://xn--80aalmlsbk.xn--p1ai/cities.json",
    {
        method: "POST",
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    }
)
    .then(response => {
        //console.log("status", response.status);

        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            return Promise.reject(new Error("проблемы с сервером"));
        }
    })
    .then(response => {
        return response.json();
    })
    .then(response => {
        towns = response;

        let options_for_cookie = {expires: 8640000, path: '/'};


        //изменение города на карте на странице Контакты
        function contact_change_city(num) {
            if (document.getElementById('contact')) {
                let phone_block = towns[num].phone;
                if (towns[num].phone2) {
                    phone_block += '<br/>' + towns[num].phone2;
                }
                phone_block += '<br/>' + towns[num].schedule;

                $(`.contact-list-cities .cities .item`).removeClass('active');
                $(`.contact-list-cities .cities .item[data-num="${num}"]`).addClass('active');
                $('.contact .contact-box .city').html('г. ' + towns[num].name + '<br/>' + towns[num].adds);
                $('.contact .contact-box .phone').html(phone_block);
                $('.contact .contact-box .yur').html(towns[num].legal_adds);
            }
        }

        //id выбраного или ближайшего города
        let id_active_city = 0;

        $("#city-block").hover(function (e) { // Mouse Over
            $(this).addClass("hover");
        }, function (e) { // Mouse Out
            $(this).removeClass("hover");
        });

//
        $('#correct-city-popup_button-yes').click(() => {
            $('#correct-city-popup').removeClass('active');
        });
        $('#correct-city-popup_button-no').click(() => {
            $('#correct-city-popup').removeClass('active');
            $("#city-block").mouseenter();
        });

        //изменение города
        function change_city(id) {
            $('#city-block .active-city').html(towns[id].name);
            let phone = towns[id].phone;
            let br = phone.indexOf('<br />');
            if (br != -1) {
                phone = phone.substring(0, br);
            }

            $('#phone-city').attr('href', 'tel:' + phone.replace(/([^0-9])/ig, ""));
            $('#phone-city').html(phone);

            $('#phone-city_footer').attr('href', 'tel:' + phone.replace(/([^0-9])/ig, ""));
            $('#phone-city_footer').html(phone);

            setCookie('town_id', id, options_for_cookie);

            //меняем город в слайдере на главной странице
            if (document.getElementById('page-main')) {
                mainSliderChangeCity(id_active_city);
            }

            //меняем почту в формах
            let input_mail = document.querySelectorAll('.hidden-input_mail');
            let mail = towns[id_active_city].mail || '';

            towns.map(item => {
                if(item.duplication === 'Да' && item !== towns[id_active_city]) {
                    if(mail !== '') mail += ', ';
                    mail += item.mail;
                }
            });

            //console.log('mail', mail);
            for(let elem of input_mail){
                elem.setAttribute('value', mail);
            }

            //добавление города в письмо
            $('.hidden-input_city').val(towns[id_active_city].name);
        }

        //добавление городов в список в шапке
        $('#city-list').html(() => {
            let str = '';
            towns.map((item, i) => {
                str += '<li data-num="' + i + '">' + item.name + '</li>';
                if (i == id_active_city) {
                    $('#city-block .active-city').text(item.name);
                }
            });

            return str;
        });
        $(`#city-list li[data-num="${id_active_city}"]`).addClass('active');

        //изменяем город при клике
        $('#city-list li').click(function () {
            id_active_city = parseInt($(this).attr('data-num'));
            change_city(id_active_city);
        });


        //добавление городов в список на странице "Контакты"
        $('.contact-list-cities .cities').html(() => {
            let str = '';
            towns.map((item, i) => {
                str += (i === id_active_city) ?
                    `<div class="item active" data-num="${i}">${item.name}</div>`
                    :
                    `<div class="item" data-num="${i}">${item.name}</div>`
                ;
            });
            return str;
        });


        if (getCookie('town_id') != undefined) {
            id_active_city = getCookie('town_id');
            change_city(id_active_city);

            contact_change_city(id_active_city);
        }


        const calculateTheDistance = (latA, longA, latB, longB) => {
            let latCosA = Math.cos(latA * Math.PI / 180);
            let latCosB = Math.cos(latB * Math.PI / 180);
            let latSinA = Math.sin(latA * Math.PI / 180);
            let latSinB = Math.sin(latB * Math.PI / 180);
            let deltaCos = Math.cos((longB * Math.PI / 180) - (longA * Math.PI / 180));
            let deltaSin = Math.sin((longB * Math.PI / 180) - (longA * Math.PI / 180));

            let y = Math.sqrt(Math.pow(latCosB * deltaSin, 2) + Math.pow(latCosA * latSinB - latSinA * latCosB * deltaCos, 2));
            let x = latSinA * latSinB + latCosA * latCosB * deltaCos;

            return Math.atan2(y, x) * 6372;
        };

        if (getCookie('town_id') == undefined || getCookie('town_id') === '') {
            //console.log('correct-city-popup');
            $('#correct-city-popup').addClass('active');
            $('.correct-city-popup_text').text(`Ваш город ${towns[id_active_city].name}. Верно?`);
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    const distances = [];
                    towns.map((town) => {
                        distances.push(calculateTheDistance(lat, lng, town.coord[0], town.coord[1]));
                    });

                    id_active_city = towns[distances.indexOf(Math.min(...distances))].id;
                    //изменяем город
                    change_city(id_active_city);
                    contact_change_city(id_active_city);

                    $('.correct-city-popup_text').text(`Ваш город ${towns[id_active_city].name}. Верно?`);
                });
            }
            else {
                //изменяем город
                change_city(id_active_city);
                contact_change_city(id_active_city);
            }
        }



        //изменение информации о филиале на странице "Контакты"
        $('.contact-list-cities .cities .item').click(function () {
            contact_change_city($(this).attr('data-num'));
        });

        //Яндекс карта на странице "Контакты"
        if (typeof(page_contact) == "undefined") {
            var page_contact = false;
        }

        if (document.getElementById('page-main') || document.getElementById('page-about')) {
            let my_dom = '';

            towns.map((item) => {
                my_dom +=
                    `<span class="punkt-city" style="top: ${item.map_top}px; left: ${item.map_left}px;">` +
                        `<img src="/wp-content/themes/NadezhniyZaim/assets/img/point.png" alt=""> ${item.name}` +
                        `<span class="punkt-city__adress">${item.adds}<br>${item.phone}</span>` +
                    ` </span>`;
            });

            document.getElementById('map-punkt').innerHTML = my_dom;
        }

        if (document.getElementById('contact')) {
            let city = ['Новосибирск'];
            let center = [55.011505, 82.937102];

            let myMap;
            let chords;


            $('.contact-list-cities .cities .item').click(function () {
                geocode(towns[$(this).attr('data-num')].coord);
            });

            function geocode(city) {
                return ymaps.geocode(city)
                    .then(result => {
                        const points = result.geoObjects.toArray();

                        if (points.length) {
                            coord = points[0].geometry.getCoordinates();
                            myMap.panTo(coord);
                        }
                    });
            };

            new Promise(resolve => ymaps.ready(resolve))
                .then(() => {
                    myMap = new ymaps.Map("map", {
                        center: towns[id_active_city].coord,
                        zoom: 15
                    });


                    let myCollection = new ymaps.GeoObjectCollection();
                    for (let i = 0; i < towns.length; i++) {
                        myCollection.add(new ymaps.Placemark(
                            towns[i].coord,
                            {
                                balloonContentHeader: towns[i].name,
                                balloonContentBody: towns[i].adds
                            }
                        ));
                    }
                    myMap.geoObjects.add(myCollection);
                });
        }

    });


/*
const towns = [
    {
        id: 0,
        coord: [55.011505, 82.937102],
        name: 'Новосибирск',
        adds: 'ул.Зыряновская 53, офис 113',
        phone: '8 (383) 248-33-22',
        schedule: 'ежедневно с (08:00 до 20:00)',
        legal_adds: 'ИНН: 5405972250 <br> ОГРН: № 1703550008229'
    },
    {
        id: 1,
        coord: [43.258721, 132.063504],
        name: 'Владивосток',
        adds: 'ул. Тёплая 6, остановка Весенняя',
        phone: '8 (950) 248-55-48',
        phone2: '8 (950) 299-55-48',
        schedule: 'ежедневно с (08:00 до 20:00)',
        legal_adds: 'ИНН: 2543099490 <br> ОГРН: № 1703605008512'
    },
    {
        id: 2,
        coord: [53.353283, 83.699017],
        name: 'Барнаул',
        adds: 'ул. Малахова 87 б',
        phone: '8 (3852) 995-000',
        schedule: 'ежедневно с (08:00 до 20:00)',
        legal_adds: 'ИНН: 2222862135 <br> ОГРН: № 1703501008638'
    },
    {
        id: 3,
        coord: [56.827601, 60.589938],
        name: 'Екатеринбург',
        adds: 'ул. Шейнкмана, 7, офис 115',
        phone: '8-912-212-85-41',
        schedule: 'ежедневно с (08:00 до 20:00)',
        legal_adds: 'ИНН: 6679103423 <br> ОГРН: № 1703465008628'
    },
    {
        id: 4,
        coord: [57.144259, 65.594272],
        name: 'Тюмень',
        adds: 'ул. Харьковская, 75 к1, офис 903',
        phone: '8 (345) 290-06-06',
        schedule: 'ежедневно с (08:00 до 20:00)',
        legal_adds: 'ИНН: 7203423798 <br> ОГРН: № 1703471008673'
    },
    {
        id: 5,
        coord: [55.431708, 65.310324],
        name: 'Курган',
        adds: 'ул. Свердлова, дом 15, помещение /2 ',
        phone: '8 (3522) 55-00-98',
        schedule: 'ежедневно с (08:00 до 20:00)',
        legal_adds: 'ИНН: 4501214071 <br> ОГРН: № 1703437008605'
    },
    {
        id: 6,
        coord: [56.311551, 44.001100],
        name: 'Нижний Новгород',
        adds: 'ул. Белинского, 60а',
        phone: '8 (831) 414-55-99',
        phone2: '8 (903) 602-55-99',
        schedule: 'ежедневно с (08:00 до 20:00)',
        legal_adds: 'ИНН: 5260442095'
    },
    {
        id: 7,
        coord: [47.214005, 39.691719],
        name: 'Ростов-на-Дону',
        adds: 'ул. Нагорная 2А, офис №1',
        phone: '8 (863) 229-90-99',
        schedule: 'ежедневно с (08:00 до 20:00)',
        legal_adds: ''
    },
    {
        id: 8,
        coord: [44.598199, 33.459397],
        name: 'Севастополь',
        adds: 'ул. Парковая, 29',
        phone: '8 (978) 264-52-66',
        schedule: 'ежедневно с (08:00 до 20:00)',
        legal_adds: 'ИНН: 9201520983 <br> ОГРН: № 1704067008653'
    }
];*/


//добовляем город в слайдер на главной странице
if (document.getElementById('page-main')) {
    //mainSliderChangeCity();
}


//маска для всех полей с номером телефона
$("input[name='phone']").mask("+7 (999) 999-9999");

//работа checkbox для политики
$('.check-privacy').find('label').click(function () {
    $(this).parent('.check-privacy').find('input[type=checkbox]').prop("checked", function (i, prop) {
        return !prop;
    });
});

//проверка для полей с номером телефона;
// если уставливается курсор в строку и до курсора нет символов  "_" - можно редактировать
//если до курсора есть "_" - курсор переходит к первому такому символу
$("input[name='phone']").on('click keyup', function () {
    var val = $(this).val();
    var n = val.indexOf('_');
    var str = val.substring(0, getCaretPosition(this).start);

    if (n != '-1' && str.indexOf('_') != -1) setCaretPosition(this, n);
});

//установка позиции каретки
function setCaretPosition(ctrl, start, end) {
    if (end === undefined) {
        end = start;
    }
    // IE >= 9 and other browsers
    if (ctrl.setSelectionRange) {
        ctrl.focus();
        ctrl.setSelectionRange(start, end);
    }
    // IE < 9
    else if (ctrl.createTextRange) {
        var range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd('character', end);
        range.moveStart('character', start);
        range.select();
    }
}

//получение позиции каретки
function getCaretPosition(ctrl) {
    // IE < 9 Support
    if (document.selection) {
        ctrl.focus();
        var range = document.selection.createRange();
        var rangelen = range.text.length;
        range.moveStart('character', -ctrl.value.length);
        var start = range.text.length - rangelen;
        return {'start': start, 'end': start + rangelen};
    }
    // IE >=9 and other browsers
    else if (ctrl.selectionStart || ctrl.selectionStart == '0') {
        return {'start': ctrl.selectionStart, 'end': ctrl.selectionEnd};
    } else {
        return {'start': 0, 'end': 0};
    }
}

//отправка формы
$('.order-form').submit(function (e) {
    //отменяем отправку формы
    e.preventDefault();
    var order_form = this;
    //если не указан номер телефона - завершаем действие функции
    if ($(this).find('input[name="phone"]').val() == '') {
        alert('Пожалуйста, укажите Ваш номер телефона');
        return false;
    }

    if (!$(this).find('input[name="privacy"]').prop("checked")) {
        alert('Вам необходимо дать согласие на обработку своих персональных данных.');
        return false;
    }

    var m_method = $(this).attr('method');
    var m_action = $(this).attr('action');
    var m_data = $(this).serialize();

    //отправляем форму по обычному action
    $.ajax({
        type: m_method,
        url: m_action,
        data: m_data,
        success: function (result) {
            order_form.reset();
            $('.modal').modal('hide');
            $('#senk').modal('show');
            //console.log(result);
        }
    });
});

$('#order-form-file').submit(function (e) {
    //отменяем отправку формы
    e.preventDefault();
    var order_form = this;

    //если не указан номер телефона - завершаем действие функции
    if ($(this).find('input[name="phone"]').length > 0) {
        if ($(this).find('input[name="phone"]').val() == '') {
            alert('Пожалуйста, укажите Ваш номер телефона');
            return false;
        }
    }

    if ($(this).find('input[name="privacy"]').length > 0) {
        if (!$(this).find('input[name="privacy"]').prop("checked")) {
            alert('Вам необходимо дать согласие на обработку своих персональных данных.');
            return false;
        }
    }

    var m_method = $(this).attr('method');
    var m_action = $(this).attr('action');

    var $input = $("#photoFile");
    var fd = new FormData(this);
    fd.append('photo', $input.prop('files')[0]);

    //отправляем форму по обычному action
    $.ajax({
        type: m_method,
        url: m_action,
        //data: m_data,
         processData: false,
         contentType: false,
        data: fd,
        success: function (result) {
            order_form.reset();
            $('.modal').modal('hide');
            $('#senk').modal('show');
            //console.log(result);
        }
    });


});


var clientWidth = document.documentElement.clientWidth,
    clientHeight = document.documentElement.clientHeight;

var main_slider2 = '.slider-example';
var n_slide = 4;//количество показов слайда
var n_slide_medium = 2;//количество показов слайда
var n_slide_small = 1;//количество показов слайда

//подсчет количества слайдеров
function countSlider(n, n_m, n_sm) {
    //определяем количество всех слайдов в блоке и выводим в контейнер all
    var count_slide = parseInt(n);
    if (clientWidth < 800 && clientWidth > 500) count_slide = parseInt(n_m);
    if (clientWidth < 500) count_slide = parseInt(n_sm);
    var countSlider = Math.ceil(parseInt($(main_slider2).slick("getSlick").slideCount) / count_slide);
    $('.slider-example + .slider-bottom .all').text('/ ' + countSlider);
}

// Разбиение на разряды
function addSpaces(nStr) {
    return String(nStr).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
}

//контроль ввода числа
function validNum(obj, maxSize, minSize) {
    var str = obj.value.trim();
    //alert(maxSize);
    //убираем все кроме чисел
    str = str.replace(/([^0-9])/ig, "");
    if (maxSize !== undefined) {
        if (parseInt(str) > parseInt(maxSize)) return false;
    }
    if (minSize !== undefined) {
        if (parseInt(str) < parseInt(minSize)) return false;
    }

    obj.value = "";
    obj.value = str;

    return true;
}


$('.main .main__imitation-click').click(function () {
    $('#main__btn-imitation').click();
});

//слайдер на главной странице первый блок
$('.main .slider').slick({
    infinite: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false
    //appendDots: $('.main .slider .dots')
});

//слайдер на главной странице "РЕАЛЬНЫЕ ПРИМЕРЫ"
$(main_slider2).slick({
    infinite: true,
    dots: true,
    arrows: false,
    slidesToShow: n_slide,
    slidesToScroll: n_slide,
    appendDots: $('.slider-example + .slider-bottom .num'),
    responsive: [
        {
            breakpoint: 800,
            //сообщает, при какой ширине экрана нужно включать настройки
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,

            }
        },
        {
            breakpoint: 500,
            //сообщает, при какой ширине экрана нужно включать настройки
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            }
        }
    ]

});

if ($('.slider-example + .slider-bottom .num').text() === '') $('.slider-example + .slider-bottom .num').html(
    '<span class="slick-active"><button>1</button></span>'
);

$(main_slider2).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    var i = (currentSlide ? currentSlide : 0) + 1;
    //$status.text(i + '/' + slick.slideCount);
    //$('.main .wr.wr5 .block .slider-bottom .num').text(i);
});

countSlider(n_slide, n_slide_medium, n_slide_small);

//определяем страницу слайдов, меняем номер  при листании страниц
//num_slider(main_slider2,'.main .wr5 .slider-bottom .num');

$(main_slider2).on('afterChange', function () {
    //num_slider(main_slider2,'.main .wr5 .slider-bottom .num');
});

function num_slider(slider, num) {
    var tt = $(slider).find('[aria-hidden="false"]').attr('data-slick-index');
    var yy = Math.ceil((parseInt(tt) + 1) / n_slide);
    if (yy < 10) yy = '0' + yy;
    $(num).text(yy);
}

// предыдущий слайд
$('.slider-example + .slider-bottom .prev').click(function () {
    $(main_slider2).slick('slickPrev');
});
//следующий слайд
$('.slider-example + .slider-bottom .next').click(function () {
    $(main_slider2).slick('slickNext');
});

//слайдер на главной странице "ПРЕИМУЩЕСТВА ЗАЙМА"
$('.slider-advant').slick({
    infinite: true,
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 800,
            //сообщает, при какой ширине экрана нужно включать настройки
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
            }
        },
        {
            breakpoint: 500,
            //сообщает, при какой ширине экрана нужно включать настройки
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            }
        }
    ]
});


//modal script
$('.open-modal').click(function () {
    $('#modal-all .modal-title').html($(this).attr('data-title'));
    $('#modal-all .modal-subtitle').html($(this).attr('data-subtitle'));
    $('#modal-all .btn-default').html($(this).attr('data-button'));
    $('#modal-all input[name="add_info"]').val($(this).attr('data-title'));
    $('#modal-all').modal('show');
});

//menu open
$('.main #open_menu').click(function () {
    $('.main-header__top-item2').toggleClass('active');
    $('.main-header__top-item3').toggleClass('active');
    $('.main-header__top-item4').toggleClass('active');
    $('.main-header__menu ul').toggleClass('active');
    $(this).toggleClass('active');
});
$('.subpage #open_menu').click(function () {
    $('.subpage .nav').toggleClass('active');
    $('.subpage .head .item2').toggleClass('active');
    $('.subpage .head .item3').toggleClass('active');
    $(this).toggleClass('active');
});


//сворачивание и разворачивание блоков вопрос-ответ (страница "Услуги")
$('.faq .item .item-title').click(function () {
    $(this).parent('.item').toggleClass('active');
});

//страница "Услуги", блок "УСЛОВИЯ ВЫДАЧИ ЗАЙМА"
$('.conditions .button-body .button').click(function () {
    $('.conditions .button-body .button').removeClass('active');
    $(this).addClass('active');

    var num = $(this).attr('data-num');
    $('.conditions .content .item-body').removeClass('active');
    $('.conditions .content .item-body[data-num=' + num + ']').addClass('active');
});

//модалка "политика конфиденциальности"
$modalPrivacy = $('#modal-privacy');

$('.open-privacy').click(function (e) {
    e.preventDefault();
    $modalPrivacy.modal('show');
});

$(".modal").find(".open-privacy").click(function (e) {
    e.preventDefault();
    $modalPrivacy.addClass("open-modal-privacy");
});
$modalPrivacy.on("hidden.bs.modal", function () {
    if ($modalPrivacy.hasClass("open-modal-privacy")) {
        $modalPrivacy.removeClass("open-modal-privacy");
        $('body').addClass("modal-open");
    }
});



//добавление нового класса в верхнее меню при скролле (для его уменьшения)
$(window).scroll(function(){
    if($(document).width() > 1170 )
    {
        if ($(this).scrollTop() >= 200) {
            if(document.getElementById('page-main')){
                $('.main-header__top').addClass('main-header__top_fixed');
                $('.main-header__menu').addClass('main-header__menu_fixed');
            }else{
                $('.wr.nav').addClass('nav_fixed');
                $('.wr.head').addClass('head_fixed');
            }
        }
        else {
            if(document.getElementById('page-main')) {
                $('.main-header__top').removeClass('main-header__top_fixed');
                $('.main-header__menu').removeClass('main-header__menu_fixed');
            }
            else{
                $('.wr.nav').removeClass('nav_fixed');
                $('.wr.head').removeClass('head_fixed');
            }
        }
    }

});

//КАЛЬКУЛЯТОР
function calculatePercentSumm(summaInp, periodInp) {
    if ($(periodInp).val().replace(/ /g, '').length == 0 || $(summaInp).val().replace(/ /g, '').length == 0) {
        return;
    }

    let summa = parseInt($(summaInp).val().replace(/ /g, ''));

    let array = [
        {
            proc: 0.035,
            selector: '#calc_summa-10day',
            month: ''
        },
        {
            proc: 0.05,
            selector: '#calc_summa-auto',
            month: periodInp
        },
        {
            proc: 0.07,
            selector: '#calc_summa-pts',
            month: periodInp
        },
        {
            proc: 0.055,
            selector: '#calc_summa-property',
            month: periodInp
        },
    ];

    array.forEach(function (item) {
        item.proc = parseFloat(item.proc);
        switch (true) {
            case summa >= 500000 && summa < 1000000:
                item.proc -= 0.002;
                break;
            case summa >= 1000000 && summa < 1500000:
                item.proc -= 0.003;
                break;
            case summa >= 1500000 && summa < 2000000:
                item.proc -= 0.004;
                break;
            case summa >= 2000000 && summa < 4000000:
                item.proc -= 0.005;
                break;
            case summa >= 4000000 && summa < 5000000:
                item.proc -= 0.01;
                break;
        }
        item.month.length > 0 ?
            $(item.selector).text(addSpaces(Math.round((summa * item.proc) / parseInt($(item.month).val().replace(/ /g, '')))) + ' руб')
            :
            $(item.selector).text(addSpaces(Math.round(summa * item.proc)) + ' руб')
    });


}

if (document.getElementById('summaInp') && document.getElementById('periodInp')) {
    calculatePercentSumm('#summaInp', '#periodInp');
    $('input#rangeSumma').slider();

    //движение ползунка
    $("input#rangeSumma").change(function () {
        $('input[name="summa"]').val(
            addSpaces(
                parseInt($(this).val().replace(/ /g, ''))
            )
        );
        calculatePercentSumm('#summaInp', '#periodInp');
    });
    //минимальная сумма
    let min_sum = addSpaces($('input#rangeSumma').attr('data-slider-min'));
    let max_sum = addSpaces($('input#rangeSumma').attr('data-slider-max'));

    $('#summaInp').on('input', function (event) {
        if (event.keyCode == 37 || event.keyCode == 39) return;

        //проверяем не выходит ли число за пределы
        if (!validNum(this, 5000000, 10000)) {
            $(this).addClass('error');
            return false;
        }
        else {
            if ($(this).hasClass('error')) $(this).removeClass('error');
        }

        $(this).val(addSpaces($(this).val()));

        let summCount = parseInt($(this).val().replace(/\s+/g, ''));
        calculatePercentSumm('#summaInp', '#periodInp');

        let rangeLeft = (summCount / 5000) * 0.1001 - 0.1001;
        $('#rangeSummaSlider .slider-selection').attr("style", "left: 0%; width: " + rangeLeft + "%;");
        $('#rangeSummaSlider .min-slider-handle').attr("style", "left: " + rangeLeft + "%;");
        $('#rangeSummaSlider .tooltip-main').attr("style", "left: " + rangeLeft + "%; margin-left: -11.5px;");
        $('#rangeSummaSlider .slider-track-high').attr("style", "right: 0%; width: " + (100 - rangeLeft) + "%;");
    });

    //бегунок на период
    $('#rangePeriod').slider();
    $("#rangePeriod").change(function () {
        let periodCount = parseInt($(this).val().replace(/ /g, ''));
        $('input[name="period"]').val(addSpaces(periodCount));
        calculatePercentSumm('#summaInp', '#periodInp');
    });

    $('input#periodInp').keyup(function () {
        if (event.keyCode == 37 || event.keyCode == 39) return;

        //проверяем не выходит ли число за пределы
        if (!validNum(this, 12, 1)) {
            $(this).addClass('error');
            return false;
        }
        else {
            if ($(this).hasClass('error')) $(this).removeClass('error');
        }

        let periodCount = parseInt($(this).val().replace(/\s+/g, ''));
        calculatePercentSumm('#summaInp', '#periodInp');

        let rangeLeft = (periodCount / 0.012) * 0.1001 - 0.1001;
        $('#rangePeriodSlider .slider-selection').attr("style", "left: 0%; width: " + rangeLeft + "%;");
        $('#rangePeriodSlider .min-slider-handle').attr("style", "left: " + rangeLeft + "%;");
        $('#rangePeriodSlider .tooltip-main').attr("style", "left: " + rangeLeft + "%; margin-left: -11.5px;");
        $('#rangePeriodSlider .slider-track-high').attr("style", "right: 0%; width: " + (100 - rangeLeft) + "%;");
    });
}

//});
$(window).resize(function () {
    countSlider(n_slide, n_slide_medium, n_slide_small);
});




